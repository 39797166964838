<template>
    <div class="base-content">
        <div class="base-box"
             v-loading="$store.state.allLoading"
             element-loading-text="加载中..."
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.8)">
            <div class="reg-box" v-if="showInfo">
                <template v-if="status==6">
                    <my-volunteer :dataInfoFu="dataInfo"/>
                </template>
                <template v-else>
                    <volunteer :dataInfoFu="dataInfo" @setStatus="setStatus"/>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import Volunteer from "c/index/Volunteer";
    import MyVolunteer from "c/index/MyVolunteer";
    import {getConfig} from "r/index/index.js";

    export default {
        name: "main-index",
        components: {Volunteer, MyVolunteer},
        data() {
            return {
                dataInfo: {
                    account: {},
                    all_province: [],
                    all_city: [],
                    amount: null,
                    school_name: null,
                    show_school: null,
                    province: null,
                    city: null,
                    my_volunteer: {},
                },
                showInfo: false,
                status: null,
            }
        },
        created() {
            this.getConfig()
        },
        methods: {
            setStatus(val) {
                this.status = val;
            },
            async getConfig() {
                let {data: res} = await getConfig({})
                // console.log(res)
                if (res.code != 200) {
                    return this.$message.error(res.message);
                }
                if (res.data && res.data.my_volunteer) {
                    this.status = res.data.my_volunteer.status;
                }
                this.showInfo = true;
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/scss/base.scss";
</style>
