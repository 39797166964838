import { request } from "../request";

// 获取配置详情
export function getConfig(data) {
    return request({
        method: "get",
        url: "/xjtu/auth/getConfig",
        data,
    });
}

// 生成或修改volunteer
export function confirmVolunteer(data) {
    return request({
        method: "post",
        url: "/xjtu/auth/confirmVolunteer",
        data,
    });
}

// 获取支付配置
export function getPay(data) {
    return request({
        method: "post",
        url: "/xjtu/auth/getPay",
        data,
    });
}

// 检查是否支付
export function checkPay(data) {
    return request({
        method: "post",
        url: "/xjtu/auth/checkPay",
        data,
    });
}
