<template>
    <el-dialog
            title=""
            :visible.sync="dialogVisible"
            width="520px"
            :close-on-click-modal="false"
            @open="open"
    >
        <div class="title">微信支付</div>
        <div class="img_qr">
            <div ref="qrCodeUrl"></div>
        </div>
        <div class="info_2" style="color: #000">请使用微信扫描二维码完成支付</div>
        <div class="info_2">
            手机端完成支付后，请等待系统处理，在此之前请勿关闭窗口
        </div>
        <div class="Pay_Btn">
            <el-button @click="cancelBtn()">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import QRCode from "qrcodejs2";

    export default {
        name: "WxPay",
        props: {
            qrUrlFu: String,
            dialogVisibleFu: Boolean,
        },
        watch: {
            dialogVisibleFu() {
                this.dialogVisible = this.dialogVisibleFu;
            },
        },
        data() {
            return {
                dialogVisible: this.dialogVisibleFu,
            };
        },
        methods: {
            open() {
                setTimeout(()=>{
                    this.createQrCode(this.qrUrlFu);
                })
            },
            // 生成二维码
            createQrCode(qrCode) {
                new QRCode(this.$refs.qrCodeUrl, {
                    text: qrCode, // 需要转换为二维码的内容
                    width: 120,
                    height: 120,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H,
                });
            },
            // 删除二维码img标签
            removeQr() {
                if (this.$refs.qrCodeUrl) {
                    // 获取 父 标签下的所有子节点
                    let pObjs = this.$refs.qrCodeUrl.childNodes;
                    for (let i = pObjs.length - 1; i >= 0; i--) {
                        // 一定要倒序，正序是删不干净的，可自行尝试
                        this.$refs.qrCodeUrl.removeChild(pObjs[i]);
                    }
                }
            },
            // 确认,关闭弹框，修改父组件的值
            cancelBtn() {
                // 关闭时，删除二维码img标签
                this.removeQr();
                this.dialogVisible = false;
                this.$emit("closeDialog", this.dialogVisible, false);
            },
        },
    };
</script>

<style scoped lang="scss">
    ::v-deep .el-dialog__header,
    ::v-deep .el-dialog__body,
    ::v-deep .el-dialog__footer {
        padding: 0 !important;
    }

    ::v-deep .el-icon-close:before {
        content: "";
    }

    .title {
        font-size: 18px;
        line-height: 40px;
        padding: 20px 0;
        color: #000000;
        font-weight: bold;
        text-align: center;
    }

    .info_2 {
        color: #999999;
        text-align: center;
        margin: 10px 0;
        line-height: 30px !important;
    }

    .img_qr {
        position: relative;
        width: 122px;
        height: 122px;
        padding: 10px;
        border: 1px solid #000;
        margin: 0 auto;

        img {
            width: 120px;
            height: 120px;
        }
    }

    .Pay_Btn {
        padding: 20px 0 40px 0;
        text-align: center;

        .el-button {
            height: 40px;
            line-height: 38px;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 400;
            padding: 0 20px;
            border: 1px solid #cccccc;
            background-color: #ffffff;
            color: #999999;
        }
    }

</style>

